<template>
  <!-- 实验用房预约 -->
  <div class="openroom">
    <router-view></router-view>
  </div>
</template>

<script>
import Mainpage from "@/views/openroom/components/mainpage.vue";
export default {
  name: "Openroom",
  components: {
    Mainpage,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.openroom {
  background: #ffffff;
  // background: #F7F7F7;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  
}
</style>
